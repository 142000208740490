<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app id="main-app">
    <template v-if="AppReady">
      <app-system-bar class="d-print-none" />
      <app-bar
        :navigation.sync="navigation"
      />
      <app-snackbar
        v-if="AppSnackbar"
        class="d-print-none"
      />
      <app-navigation
        v-model="navigation"
      />
      <v-main
        id="main-content"
        class="main-container"
      >
        <app-progress-linear
          v-if="!AppIsSmall"
          class="d-print-none"
        />
        <slide-fade-transition>
          <layout-router-view v-if="AppReady" />
        </slide-fade-transition>
        <slide-fade-transition>
          <slot v-if="AppReady" />
        </slide-fade-transition>
      </v-main>
      <v-bottom-navigation
        v-if="AppReady && AppIsSmall && authUser"
        app
        background-color="gray"
        class="d-none rounded-t"
        grow
        height="60"
        mandatory
      >
        <v-btn
          v-for="e in bottomNavigationButtons"
          :key="e.value"
          :to="e.to"
          color="gray"
          depressed
          exact-path
          height="60"
          link
          plain
        >
          <span>{{ parseAttribute(e.text) }}</span>
          <!--<v-badge-->
          <!--  v-if="UnreadAppNotifications>0 && e.badge"-->
          <!--  content="*"-->
          <!--  :acontent="UnreadAppNotifications"-->
          <!--  color="transparent"-->
          <!--  class="error&#45;&#45;text"-->
          <!--  overlap-->
          <!--&gt;-->
          <!--  <v-icon>-->
          <!--    {{ e.icon }}-->
          <!--  </v-icon>-->
          <!--</v-badge>-->
          <v-icon :color="UnreadAppNotifications>0 && e.badge ? 'error' : undefined">
            {{ e.icon }}
          </v-icon>
        </v-btn>
      </v-bottom-navigation>
      <app-main-footer
        v-if="AppReady && !AppIsSmall"
        class="d-print-none"
      />
    </template>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LayoutRouterView from '@/layouts/LayoutRouterView'
import { MetaInfoMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'AppLayout',
  components: { SlideFadeTransition, LayoutRouterView },
  mixins: [MetaInfoMixin],
  data () {
    return {
      navigation: this.$vuetify.breakpoint.mdAndUp,
      // navigation: !0,
      bottomNavigation: null
    }
  },
  computed: {
    bottomNavigationButtons () {
      return [
        {
          text: 'home',
          icon: 'mdi-home',
          value: this.APP_ROUTES.homePage,
          to: this.route(this.APP_ROUTES.homePage)
        },
        // {
        //   text: '',
        //   icon: 'mdi-book',
        //   value: this.APP_ROUTES..index,
        //   to: this.route(this.APP_ROUTES..index)
        // },
        {
          text: 'profilePage.name',
          icon: 'mdi-account',
          value: this.APP_ROUTES.user.profile,
          to: this.route(this.APP_ROUTES.user.profile)
        },
        {
          text: 'notifications',
          icon: 'mdi-bell',
          value: this.APP_ROUTES.user.notifications,
          badge: this.APP_ROUTES.user.notifications,
          to: this.route(this.APP_ROUTES.user.notifications)
        }
      ]
    },
    AppSnackbar: {
      set (value) {
        mapMutations('snackbar', ['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters('snackbar', ['getShow']).getShow.call(this)
      }
    }
  },
  watch: {
    // $route: {
    //   deep: !0,
    //   immediate: !0,
    //   handler: 'checkAuthDialog'
    // },
  },
  mounted () {
  },
  methods: {}
}
</script>
<style scoped>
@media print {
  .main-container {
    background: none !important;
  }

  .v-main {
    /*padding-top: 0 !important;*/
    padding: 0 !important;
  }
}

.main-container {
  /*min-height: 90vh;*/
  /*background: url(/assets/images/bg/artwork.png);*/
  /*background-position: -125px -125px;*/
  /*padding-bottom: 50px;*/
}

.main-container > .v-main__wrap {
  /*padding-bottom: 50px;*/
}
</style>
